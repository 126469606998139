import React, { useState, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import LazyImage from 'components/shared/LazyImage';
import Icon from 'components/shared/Icon';
import closeIcon from 'assets/icons/close.svg';

import useOutsideClick from 'hooks/useOutsideClick';
import useMedia from 'hooks/useMedia';

const ModalOverlay = styled.aside`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0000009e;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  ${({ visible }) =>
    visible &&
    css`
      opacity: 1;
      visibility: visible;
    `}
`;

const Content = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  min-width: 250px;
  width: 70%;
  max-width: 350px;
  border: 1px solid #292320;

  ${({ theme }) => theme.mq.md} {
    min-width: 570px;
    width: 60%;
    max-width: 940px;
  }
`;

const CloseIconWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  background-color: transparent;
  cursor: pointer;
`;

const HomepagePromoModal = ({ images, links }) => {
  const [isVisible, setIsVisible] = useState(false);

  const closeModal = () => {
    sessionStorage.setItem('popupClosed', true);
    setIsVisible(false);
  };

  useEffect(() => {
    if (!sessionStorage.getItem('popupClosed')) {
      setTimeout(() => {
        setIsVisible(true);
      }, 200);
    }
  }, []);

  const modalRef = useRef(null);
  useOutsideClick(modalRef, closeModal, null, isVisible);

  const matchesMD = useMedia('md');

  return (
    <ModalOverlay visible={isVisible}>
      <Content>
        <a
          href={matchesMD ? links.desktop : links.mobile}
          ref={modalRef}
          visible={isVisible}
        >
          <LazyImage src={matchesMD ? images.desktop : images.mobile} />
        </a>
        <CloseIconWrapper onClick={() => closeModal()}>
          <Icon src={closeIcon} alt="close" size={matchesMD ? 22 : 15} />
        </CloseIconWrapper>
      </Content>
    </ModalOverlay>
  );
};

HomepagePromoModal.propTypes = {
  images: PropTypes.arrayOf(PropTypes.object).isRequired,
  links: PropTypes.shape({
    mobile: PropTypes.string.isRequired,
    desktop: PropTypes.string.isRequired,
  }).isRequired,
};

export default HomepagePromoModal;
